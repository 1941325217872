import styled from 'styled-components'
import { Link, LoginForm, Main, MainMaxWidth } from 'ui'

const FooterStyles = styled.div`
  border-top-color: ${({ theme }) => theme.colors.border};
  border-top-style: solid;
  border-top-width: 1px;
  display: flex;
  grid-area: footer;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
`

const SignupFooter = (): JSX.Element => {
  return (
    <FooterStyles>
      <Link href="/signup">{"Don't have an account? Sign Up"}</Link>
    </FooterStyles>
  )
}

export default function Auth() {
  return (
    <>
      <Main>
        <MainMaxWidth>
          <LoginForm />
        </MainMaxWidth>
      </Main>
      <SignupFooter />
    </>
  )
}
